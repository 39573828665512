import UI from  '../../../core/helpers/UI';
import MainService from '../services/MainService';

class DetailController {
  context;
  main_service;
  is_valid;

  constructor(context) {
    this.context = context;
    this.main_service = new MainService();

    this._infoProfile();
  }

  _infoProfile() {
    UI.loading('show');
    this.main_service.infoProfile()
    .then((r) => {
      if(r.success == true) {
        this.context.user_id = r.data.usuario_id;
        this.context.photo = r.data.foto;

        let first_name = r.data.pri_nombre != null ? r.data.pri_nombre : '';
        let second_name = r.data.seg_nombre != null ? r.data.seg_nombre : '';
        let first_surname = r.data.pri_apellido != null ? r.data.pri_apellido : '';
        let second_surname = r.data.seg_apellido != null ? r.data.seg_apellido : '';

        this.context.names = first_name + ' ' + second_name;
        this.context.surnames = first_surname + ' ' + second_surname;
        // this.context.name_modal = first_name + ' ' + first_surname;
        this.context.name_modal = first_name + ' ' + second_name + ' ' + first_surname + ' ' + second_surname;

        this.context.position = r.data.name_position;
        this.context.type = r.data.name_tipo;
        this.context.phone_number = r.data.telefono;
        this.context.email = r.data.email;
        this.context.qr_code = r.data.qr_code;

        this.context.website = r.data.website;
        this.context.twitter = r.data.twitter;
        this.context.facebook = r.data.facebook;
        this.context.linkedin = r.data.linkedin;
        this.context.instagram = r.data.instagram;
        this.context.skype = r.data.skype;

        if(r.data.code_template) {
          this.context.template = r.data.code_template;
        }

        UI.loading('hide');
      }
    }).catch((e) => {
      UI.loading('hide');
      UI.mensajeBad('', e.msg);
    });
  }
  async getProfileData(userId) {
    try {
      const response = await this.manage_service.infoProfile(userId);
      if (response.status === 200 && response.data) {
        return {
          photo: response.data.photo || '',
          names: response.data.names || '',
          surnames: response.data.surnames || '',
          position: response.data.position || '',
          type: response.data.type || '',
          phone_number: response.data.phone_number || '',
          email: response.data.email || '',
          qr_code: response.data.qr_code || '',
          website: response.data.website || '',
          twitter: response.data.twitter || '',
          facebook: response.data.facebook || '',
          linkedin: response.data.linkedin || '',
          instagram: response.data.instagram || '',
          skype: response.data.skype || '',
          template: response.data.template || ''
        };
      } else {
        throw new Error('No se pudo obtener la información del perfil');
      }
    } catch (error) {
      console.error('Error en getProfileData:', error);
      throw error;
    }
  }

  openQrCode() {
    this.context.$set(this.context.dialog_qr, 0 , true);
  }

  closeQrCode() {
    this.context.$set(this.context.dialog_qr, 0 , false);
  }
}

export default DetailController;