<template>
  <div id="app" class="app-v3" data-app>
    <router-view></router-view>
  </div>
</template>

<script>
import Enviroment from '../helpers/Enviroment';
import Session from '../helpers/Session';

export default {
  mounted() {
    console.log("Componente montado");
    this.initialize();
  },
  // methods: {
  //   initialize(){
  //     console.log("Inicializando...");
      
  //     let app = document.querySelector('#app');
  //     console.log("Elemento #app encontrado:", app);
      
  //     if(!Enviroment.isProyectOM()){
  //       console.log("El proyecto es OM");
        
  //       var link = document.createElement('link');
  //       link.rel = "stylesheet";
        
  //       let cuentastr = Session.get('cuentastr');
  //       console.log("Valor de cuentastr obtenido de la sesión:", cuentastr);
        
  //       // Usar la URL predeterminada
  //       const baseUrl = "http://app.produccionsitios.com:9801";
        
  //       // Construir la URL de la hoja de estilos con la URL predeterminada
  //       link.href = `${baseUrl}/configuration/api/appearance/get-styles/cuenta/${cuentastr}`;
  //       console.log("URL generada para la hoja de estilos:", link.href);
        
  //       app.append(link);
  //       console.log("Hoja de estilos agregada al DOM:", link);
  //     } else {
  //       console.log("El proyecto NO es OM");
  //     }
  //   }
  // }

  methods: {
  initialize() {
    console.log("Inicializando...");
    
    let app = document.querySelector('#app');
    console.log("Elemento #app encontrado:", app);
    
    if (!Enviroment.isProyectOM()) {
      console.log("El proyecto es OM");

      // Definir los estilos en una variable
      const styles = `
        /* Color Top */
        .app-v1 .navbar { background-color: [color_top] !important; }            
        .app-v2 .navbar { background-color: [color_top] !important; }            
        .app-v3 .navbar { background-color: [color_top] !important; }
        .app-v3 .navbar .menu-header .navbar-nav .sub-menu-header .box-menu .sub-menu a, 
        .app-v3 .navbar .menu-header .navbar-nav .sub-menu-header .box-menu .sub-menu .text {
          color: #424953 !important;
        }
        
        /* Color Menu */
        .app-v1 #nav-fixed { background-color: [color_menu] !important; }
        .app-v1 .nav-fixed .box-logo, .app-v1 .nav-fixed .bar-actions-nav { background-color: transparent !important; }
        .app-v2 #nav-fixed { background-color: [color_menu] !important; }
        .app-v2 .nav-fixed .box-logo, .app-v2 .nav-fixed .bar-actions-nav { background-color: transparent !important; }
        .app-v3 #nav-fixed { background-color: [color_menu] !important; }
        .app-v3 .nav-fixed .box-logo, .app-v3 .nav-fixed .bar-actions-nav { background-color: transparent !important; }
        .app-v3 .nav-fixed .box-logo img.complete {
          content: url(../assets/images/logo-login.png) !important;
        }
        .app-v3 .nav-fixed .box-logo img.letter {
          content: url(../assets/images/logo-login-min.png) !important;
        }

        /* Color Fondo */
        .app-v1 body { background-color: [color_fondo] !important; }
        .app-v2 section { background-color: [color_fondo] !important; }
        .app-v3 section { background-color: [color_fondo] !important; }
      `;

      // Crear un bloque de estilo
      const styleSheet = document.createElement("style");
      styleSheet.type = "text/css";
      styleSheet.innerText = styles;

      // Agregar el bloque de estilo al DOM
      document.head.appendChild(styleSheet);
      console.log("Estilos agregados directamente al DOM");

    } else {
      console.log("El proyecto NO es OM");
    }
  }
}

};
</script>
