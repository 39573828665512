<template>
  <div>
    <section class="main">
      <div class="container-fluid main-box-content contentModulo pt-2 pb-4">
        <div class="breadcrumb px-3">
          <ul>
            <li>Inicio</li>
            <li>Mapa</li>
          </ul>
        </div>

        <div class="title">
          <h1 class="my-0 fw-700">Mapa</h1>
        </div>

        <div class="row pb-4">
          <div class="col-lg-4 mt-3 mt-md-4">
            <v-card>
              <v-card-title>
                Admin
              </v-card-title>

              <v-divider class="my-2"></v-divider>

              <v-card-subtitle class="fw-500 pb-0">
                Usuarios
              </v-card-subtitle>

              <v-card-text>
                <ul class="list-unstyled m-0 p-0">
                  <li>
                    <v-btn text color="primary" href="#/users/list" class="px-0">Listado</v-btn>
                  </li>
                  <li>
                    <v-btn text color="primary" href="#/users/manage" class="px-0">Crear usuario</v-btn>
                  </li>
                  <li>
                    <v-btn text color="primary" href="#/users/types-users" class="px-0">Tipos de usuarios</v-btn>
                  </li>
                  <li>
                    <v-btn text color="primary" href="#/users/positions-users" class="px-0">Cargos de usuarios</v-btn>
                  </li>
                </ul>
              </v-card-text>

              <v-divider class="my-2"></v-divider>

              <v-card-subtitle class="fw-500 pb-0">
                Plantillas
              </v-card-subtitle>

              <v-card-text>
                <ul class="list-unstyled m-0 p-0">
                  <li>
                    <v-btn text color="primary" href="#/templates/list" class="px-0">Listado</v-btn>
                  </li>
                  <li>
                    <v-btn text color="primary" href="#/templates/manage" class="px-0">Gestionar</v-btn>
                  </li>
                </ul>
              </v-card-text>

              <v-divider class="my-2"></v-divider>

              <v-card-subtitle class="fw-500 pb-0">
                Portafolio
              </v-card-subtitle>

              <v-card-text>
                <ul class="list-unstyled m-0 p-0">
                  <li>
                    <v-btn text color="primary" href="#/briefcase/list" class="px-0">Listado</v-btn>
                  </li>
                  <li>
                    <v-btn text color="primary" href="#/briefcase/manage" class="px-0">Gestionar</v-btn>
                  </li>
                </ul>
              </v-card-text>

              <v-divider class="my-2"></v-divider>

              <v-card-subtitle class="fw-500 pb-0">
                Empresa
              </v-card-subtitle>

              <v-card-text>
                <ul class="list-unstyled m-0 p-0">
                  <li>
                    <v-btn text color="primary" href="#/company/update" class="px-0">Actualizar</v-btn>
                  </li>
                </ul>
              </v-card-text>

              <v-divider class="my-2"></v-divider>

              <v-card-subtitle class="fw-500 pb-0">
                Perfil
              </v-card-subtitle>

              <v-card-text>
                <ul class="list-unstyled m-0 p-0">
                  <li>
                    <v-btn text color="primary" href="#/profile/" class="px-0">Mi perfil</v-btn>
                  </li>
                </ul>
              </v-card-text>
            </v-card>
          </div>
          <div class="col-lg-4 mt-3 mt-md-4">
            <v-card>
              <v-card-title>
                Web
              </v-card-title>

              <v-divider class="my-2"></v-divider>

              <v-card-text>
                <ul class="list-unstyled m-0 p-0">
                  <li>
                    <v-btn text color="primary" href="#/web/home" class="px-0">Home</v-btn>
                  </li>
                  <li>
                    <v-btn text color="primary" href="#/web/briefcase/4" class="px-0">Portafolio</v-btn>
                  </li>
                  <li>
                    <v-btn text color="primary" href="#/web/company" class="px-0">Empresa</v-btn>
                  </li>
                  <li>
                    <v-btn text color="primary" href="#/web/profile/7" class="px-0">Perfil</v-btn>
                  </li>
                  <li>
                    <v-btn text color="primary" href="#/web/lector" class="px-0">Lector QR</v-btn>
                  </li>
                </ul>
              </v-card-text>
            </v-card>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import Helpers from '../../../core/helpers/Helpers';

export default {
  name: 'MapView',
  data() {
    return {
      Helpers,
      list_controller: null
    };
  },

  mounted() {
  },

  methods: {
  }
}
</script>