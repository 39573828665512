
import MainRepository from "../repositories/MainRepository";

class MainService {
  main_repository;

  constructor() {
    this.main_repository = new MainRepository();
  }

  async infoProfile() {
    let r = await this.main_repository.infoProfile();
    return r;
  }
  // async getUserId() {
  //   try {
  //     let r = await this.infoProfile();
  //     if (r.success && r.data && r.data.usuario_id) {
  //       return r.data.usuario_id;
  //     } else {
  //       throw new Error('No se pudo obtener el ID del usuario');
  //     }
  //   } catch (error) {
  //     console.error('Error al obtener el ID del usuario:', error);
  //     throw error;
  //   }
  // }
}

export default MainService;