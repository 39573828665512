import Principal from '../../core/layouts/Principal.vue';
import ProfileMain from './views/ProfileMain.vue';  // Descomentado este import
import Manage from './views/Manage.vue';

window.routes.push({
  path: '/profile/:id',
  component: Principal,
  children: [
    {
      path: '',
      name: 'profile',
      component: ProfileMain,
    },
    {
      path: 'manage',
      name: 'manage',
      component: Manage,
    },
  ],
});