<template>
  <div>
    <section class="main">
      <div class="container-fluid main-box-content contentModulo pt-2 pb-4">
        <div class="breadcrumb px-3">
          <ul>
            <li>Inicio</li>
            <li>Plantillas</li>
            <li>{{!is_edit ? 'Crear' : 'Actualizar'}} plantilla</li>
          </ul>
        </div>

        <div class="title">
          <h1 class="my-0 fw-700">{{!is_edit ? 'Crear' : 'Actualizar'}} plantilla</h1>
        </div>

        <v-card class="mt-3 mt-md-4">
          <v-card-text>
            <v-form class="w-100" ref="form_template" method="post" v-model="form_valid" lazy-validation @submit.prevent="manageTemplate()">
              <v-text-field filled label="Titulo" v-model="name" :rules="[rules.requerido]"></v-text-field>
              
              <editor
                label="Código"
                v-model="code"
                :rules="[rules.requerido]"
                api-key="ervrkz377gfbh7cir9aeb9xq3yhhdmffd0vv92gazvndi40r"
                :init="{
                  height: 400,
                  menubar: false,
                  plugins: [
                    'advlist autolink lists link image charmap print preview anchor',
                    'searchreplace visualblocks code fullscreen',
                    'insertdatetime media table paste code help wordcount'
                  ],
                  toolbar: 'undo redo | code | \
                  bullist numlist outdent | removeformat',
                }"
                output-format="html"
              />

              <v-btn type="submit" rounded color="primary" min-width="150" class="d-table mx-auto mt-3">Guardar</v-btn>
            </v-form>
          </v-card-text>
        </v-card>
      </div>
    </section>
  </div>
</template>

<script>
import Helpers from '../../../core/helpers/Helpers';
import Editor from '@tinymce/tinymce-vue';
import ManageController from '../controllers/ManageController';

export default {
  name: 'GestionPlantilla',
  components: {
    editor: Editor
  },
  data() {
    return {
      Helpers,

      name: '',
      code: '',
      template_id: null,
      is_edit: false,

      form_valid: true,
      rules: {
        requerido: value => !!value || 'Campo obligatorio',
        email: value => !value || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(value) || 'Email incorrecto',
      },

      manage_controller: null
    };
  },

  mounted() {
    this.manage_controller = new ManageController(this);
    const templateId = this.$route.params.id;
  if (templateId) {
    this.is_edit = true;
    this.template_id = templateId;
    this.obtenerDatosPlantilla(templateId);
  }
  },

  methods: {
    obtenerDatosPlantilla(templateId) {
    // Suponiendo que tiene un endpoint de API para obtener una sola plantilla
    this.manage_controller.obtenerPlantillaPorId(templateId)
      .then(plantilla => {
        this.name = plantilla.name;
        this.code = plantilla.code;
      })
      .catch(error => {
        console.error('Error al obtener la plantilla:', error);
        // Manejar el error (por ejemplo, mostrar un mensaje de error al usuario)
      });
  },
    manageTemplate() {
      this.manage_controller.manageTemplate();
    }
  }
}
</script>