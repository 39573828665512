import Rest from '../../../libs/Rest';
import ErrorManager from "../../../libs/ServiceResponse/ErrorManager";
import Core from "../../../helpers/Core";
import Enviroment from "../../../helpers/Enviroment";
import UI from  '../../../helpers/UI';
import SessionUser from "../../../libs/SessionUser";
import { EventSystem } from "../../../libs/Events";

class LoginController {
  context;
  endPoint;

  constructor(context) {
    this.context = context;
    this.endPoint = Enviroment.getLoginEndpoint() || 'https://soy.esenttia.co/api/login-ingresar';
    let session_user = new SessionUser();
    session_user.destroyAll();

    if (Enviroment.getAppEnv() !== 'local' && Enviroment.isProyectOM()) {
      location.href = Enviroment.getBaseUrlApic();
    }
  }

  loginUser() {
    const rest = this.configureRestClient();

    console.log('Enviando solicitud de login a:', this.endPoint);

    rest.post()
      .then((response) => {
        console.log('Respuesta recibida:', response);
        if (response && response.data) {
          this.handleSuccessfulLogin(response.data);
        } else {
          throw new Error('Respuesta vacía o inválida');
        }
      })
      .catch((e) => {
        console.error('Error en la solicitud de login:', e);
        new ErrorManager(e, () => {
          UI.mensajeError('Error de inicio de sesión', e.message || 'No se pudo iniciar sesión. Por favor, intente nuevamente.');
        });
      });
  }

  configureRestClient() {
    const rest = new Rest();
    rest.setEndPoint(this.endPoint);
    rest.setParams({
      correo: this.context.email,
      contrasenia: this.context.password,
    });
    return rest;
  }

  handleSuccessfulLogin(userData) {
    console.log('Datos de usuario recibidos:', userData);

    if (!userData || !userData.token) {
      console.error('Datos de usuario inválidos o token faltante');
      UI.mensajeError('Error de inicio de sesión', 'Datos de usuario inválidos');
      return;
    }

    try {
      this.setUserSession(userData);
      this.redirectUser(userData.grupoTipo);
      UI.mensajeOk('INGRESO AL SISTEMA', "Bienvenido al sistema.");
      EventSystem.emit('loginUserSusses', { data: userData });
    } catch (error) {
      console.error('Error al procesar datos de usuario:', error);
      UI.mensajeError('Error de inicio de sesión', 'Error al procesar datos de usuario');
    }
  }

  setUserSession(userData) {
    Core.setToken(userData.token);
    Core.setCuentaString(userData.cuentastr);

    let session_user = new SessionUser();
    session_user.setUserId(userData.usuario_id);
    session_user.setNamePublic(`${userData.nombre} ${userData.apellido}`);
    session_user.setTypeUser(userData.grupoTipo);
    session_user.setImageProfile(userData.foto_perfil);
    session_user.setSlugUser(userData.slug);
    session_user.setToken(userData.token);
  }

  redirectUser(userType) {
    if (userType === 'cli') {
      this.context.$router.push('/client/home');
    } else {
      this.context.$router.push(Enviroment.getUrlDashboard());
    }
  }
}

export default LoginController;