import FileLib from '../libs/File'
import Enviroment from "./Enviroment";  // Mantén esta línea como está

class File {
  static uploadFile(eventfile, route, cb) {
    var file = new FileLib();
    file.uploadFile(eventfile, route, (information) => {
      cb(information);
    });
  }

  static fileUrl(file_route) {
    // Primero, decodificamos la ruta si contiene un token
    // const decodedRoute = this.decodeTokenizedRoute(file_route);
console.log(file_route);
    if (Enviroment.getExternalLoadFile() === 'true') { 
      console.log('false');
 // Usa Enviroment en lugar de Environment
      return `${Enviroment.getExternalBaseUrl()}zfiles/archivos/users/`+file_route;  // Usa Enviroment.getExternalBaseUrl()
    } else {
      console.log('true');

      return `${Enviroment.getUrlZfiles()}`;  // Usa Enviroment.getUrlZfiles()
    }
  }

  static fileUrlbrief(file_route) {
    // Primero, decodificamos la ruta si contiene un token
    // const decodedRoute = this.decodeTokenizedRoute(file_route);
console.log(file_route);
    if (Enviroment.getExternalLoadFile() === 'true') { 
      console.log('false');
 // Usa Enviroment en lugar de Environment
      return `${Enviroment.getExternalBaseUrl()}zfiles/archivos/briefcases/`+file_route;  // Usa Enviroment.getExternalBaseUrl()
    } else {
      console.log('true');

      return `${Enviroment.getUrlZfiles()}`;  // Usa Enviroment.getUrlZfiles()
    }
  }

  static decodeTokenizedRoute(route) {
    // Si la ruta contiene '-tk-', la decodificamos
    if (route.includes('-tk-')) {
      const [token, encodedPath] = route.split('-tk-');
      return decodeURIComponent(encodedPath);
    }
    // Si no, devolvemos la ruta original
    return route;
  }

  static getUrlZfiles() {
    return Enviroment.getEnvVariable('VUE_APP_API_URL') || 'https://soy.esenttia.co/';  // Usa Enviroment.getEnvVariable
  }

  static async safeUploadFile(eventfile, route) {
    return new Promise((resolve, reject) => {
      this.uploadFile(eventfile, route, (information) => {
        if (information.error) {
          reject(new Error(information.error));
        } else {
          resolve(information);
        }
      });
    });
  }
}

export default File;