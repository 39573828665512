<template>
  <div>
    <section class="main">
      <div class="container-fluid main-box-content contentModulo pt-2 pb-4">
        <div class="breadcrumb px-3">
          <ul>
            <li>Inicio</li>
            <li>Usuarios</li>
            <li>{{!is_edit ? 'Crear' : 'Actualizar'}}</li>
          </ul>
        </div>

        <div class="title">
          <h1 class="my-0 fw-700">{{!is_edit ? 'Crear' : 'Actualizar'}} usuario</h1>
        </div>

        <v-card class="mt-3 mt-md-4">
          <v-card-text>
            <v-form class="w-100" ref="form_user" method="post" v-model="form_valid" lazy-validation @submit.prevent="manageUser()">
              <div class="row">
                <div class="col-md-6">
                  <v-file-input filled label="Foto de perfil" :prepend-icon="null" @change="uploadPhoto($event)" v-model="upload_photo" v-if="!photo"></v-file-input>

                  <div class="row pb-4" v-if="photo">
                    <div class="col-sm-8">
                      <img :src="File.fileUrl(photo)" class="d-block" width="100%" alt="">
                    </div>
                    <div class="col-sm-4">
                      <v-btn color="danger" @click="deletePhoto()" width="100%">Eliminar</v-btn>
                    </div>
                  </div>
                </div>
                <div class="col-md-6"></div>
                <div class="col-md-6">
                  <v-text-field filled label="Primer nombre" v-model="first_name" :rules="[rules.requerido]"></v-text-field>
                </div>
                <div class="col-md-6">
                  <v-text-field filled label="Segundo nombre" v-model="second_name"></v-text-field>
                </div>
                <div class="col-md-6">
                  <v-text-field filled label="Primer apellido" v-model="first_surname" :rules="[rules.requerido]"></v-text-field>
                </div>
                <div class="col-md-6">
                  <v-text-field filled label="Segundo apellido" v-model="second_surname"></v-text-field>
                </div>
                <div class="col-md-6">
                  <v-select filled label="Tipo de documento" :items="type_id_list" item-text="name" item-value="type_id" v-model="type_id" :rules="[rules.requerido]"></v-select>
                </div>
                <div class="col-md-6">
                  <v-text-field filled label="Número de identidad" type="number" v-model="number_id"></v-text-field>
                </div>
                <div class="col-md-6">
                  <v-select filled label="Tipo de usuario" :items="type_user_list" item-text="nombre" item-value="tipo_id" v-model="type_user" :rules="[rules.requerido]"></v-select>
                </div>
                <div class="col-md-6">
                  <v-text-field filled label="Correo electrónico" type="email" v-model="email" :rules="[rules.email, rules.requerido]"></v-text-field>
                </div>
                <div class="col-md-6">
                  <v-select filled label="Cargo" :items="position_list" item-text="name" item-value="position_id" v-model="position" :rules="[rules.requerido]"></v-select>
                </div>
                <div class="col-md-6">
                  <v-text-field filled label="Dirección" type="text" v-model="address"></v-text-field>
                </div>
                <div class="col-md-6">
                  <v-text-field filled label="Teléfono" type="tel" v-model="phone_number"></v-text-field>
                </div>
                <div class="col-md-6" v-if="!is_edit">
                  <v-text-field filled label="Contraseña" :append-icon="!show_pass ? 'mdi-eye' : 'mdi-eye-off'" :type="show_pass ? 'text' : 'password'" @click:append="show_pass = !show_pass" v-model="password" :rules="[rules.requerido]"></v-text-field>
                </div>
                <div class="col-md-6" v-if="!is_edit">
                  <v-text-field filled label="Confirmar contraseña" :append-icon="!show_re_pass ? 'mdi-eye' : 'mdi-eye-off'" :type="show_re_pass ? 'text' : 'password'" @click:append="show_re_pass = !show_re_pass" v-model="re_password" :rules="[rules.requerido]"></v-text-field>
                </div>
                <div class="col-md-6">
                  <!-- <v-text-field filled label="Facebook" type="url" v-model="facebook" :rules="[rules.requerido]"></v-text-field> -->
                  <v-text-field filled label="Facebook" type="url" v-model="facebook"></v-text-field>
                </div>
                <div class="col-md-6">
                  <v-text-field filled label="Instagram" type="url" v-model="instagram"></v-text-field>
                </div>
                <div class="col-md-6">
                  <v-text-field filled label="Twitter" type="url" v-model="twitter"></v-text-field>
                </div>
                <div class="col-md-6">
                  <v-text-field filled label="Linkedin" type="url" v-model="linkedin"></v-text-field>
                </div>
                <div class="col-md-6">
                  <v-text-field filled label="Skype" type="url" v-model="skype"></v-text-field>
                </div>
                <div class="col-md-6">
                  <v-text-field filled label="Sitio web" v-model="website" type="url"></v-text-field>
                </div>
                <!-- <div class="col-md-6">
                  <h3 class="my-0 fw-400 fz-18 text-black fw-600">¿Crear tarjeta?</h3>

                  <v-radio-group v-model="show_card" row :rules="[rules.requerido]" class="row-radio mt-3">
                    <v-radio value="1" label="Si" class="text-black mr-4"></v-radio>
                    <v-radio value="2" label="No" class="text-black"></v-radio>
                  </v-radio-group>
                </div> -->
                <div class="col-md-6">
                  <v-select filled label="Plantilla" :items="list_templates" item-text="name" item-value="template_id" v-model="template" :rules="[rules.requerido]"></v-select>
                </div>
              </div>

              <v-btn type="submit" rounded color="success" min-width="150" class="d-table mx-auto mt-3">{{!is_edit ? 'Guardar' : 'Actualizar'}}</v-btn>
            </v-form>
          </v-card-text>
        </v-card>

        <v-card class="mt-3 mt-md-4" v-if="is_edit">
          <v-card-title class="px-md-4 pb-0 fw-700">
            Actualizar contraseña
          </v-card-title>
          <v-card-text class="pb-0 px-md-4 pt-md-4">
            <v-form class="w-100" ref="form_password" @submit.prevent="onSubmit">
              <div class="row">
                <div class="col-md-10">
                  <div class="row">
                    <div class="col-md-6">
                      <v-text-field
                        id="password"
                        filled
                        label="Contraseña"
                        :append-icon="!show_pass ? 'mdi-eye' : 'mdi-eye-off'"
                        :type="show_pass ? 'text' : 'password'"
                        @click:append="show_pass = !show_pass"
                        v-model="password"
                        :rules="[rules.requerido, rules.passwordStrength]"
                        :error-messages="passwordErrors"
                        aria-describedby="password-description"
                      ></v-text-field>
                      <div id="password-description" class="visually-hidden">Haga clic en el icono del ojo para mostrar u ocultar la contraseña</div>
                    </div>
                    <div class="col-md-6">
                      <v-text-field
                        id="confirm_password"
                        filled
                        label="Confirmar contraseña"
                        :append-icon="!show_re_pass ? 'mdi-eye' : 'mdi-eye-off'"
                        :type="show_re_pass ? 'text' : 'password'"
                        @click:append="show_re_pass = !show_re_pass"
                        v-model="re_password"
                        :rules="[rules.requerido, rules.passwordMatch]"
                        :error-messages="rePasswordErrors"
                        aria-describedby="confirm-password-description"
                      ></v-text-field>
                      <div id="confirm-password-description" class="visually-hidden">Haga clic en el icono del ojo para mostrar u ocultar la confirmación de contraseña</div>
                    </div>
                  </div>
                </div>
                <div class="col-md-2">
                  <v-btn color="success" @click="onSubmit" min-height="56" width="100%">Guardar</v-btn>
                </div>
              </div>
            </v-form>
          </v-card-text>
        </v-card>
      </div>
    </section>
  </div>
</template>

<script>
import Helpers from '../../../core/helpers/Helpers';
import File from "../../../core/helpers/File";
import ManageController from '../controllers/ManageController';
import ManageService from '../services/ManageService';


export default {
  manageService: new ManageService(),

  name: 'UserManage',
  data() {
    return {
      Helpers,
      File,

      first_name: '',
      second_name: '',
      first_surname: '',
      second_surname: '',
      type_id: '',
      number_id: '',
      type_user: '',
      email: '',
      position: '',
      password: '',
      show_pass: false,
      re_password: '',
      show_re_pass: false,
      upload_photo: null,
      //photo: '8ae0206c489ce5-tk-_sl_Fcontacts-profile_sl_F2022_sl_F07_sl_F26_sl_F1658874230_om_Cat%25C3%25A1logo-Blackday-Store.jpg',
      photo: '',
      template: '',
      show_card: '1',
      website: '',
      facebook: '',
      instagram: '',
      twitter: '',
      linkedin: '',
      skype: '',
      phone_number: '',
      address: '',

      type_id_list: [],
      type_user_list: [],
      position_list: [],
      list_templates: [],
      list_created_card: [],

      user_id: '',
      is_edit: false,
      
      form_valid: true,
      rules: {
      requerido: v => !!v || 'Este campo es requerido',
      passwordStrength: v => (v && v.length >= 8) || 'La contraseña debe tener al menos 8 caracteres',
      passwordMatch: () => this.password === this.re_password || 'Las contraseñas no coinciden'
    },

      manage_controller: null
    };
  },

  computed: {
     photoUrl() {
      return this.photo ? File.fileUrl(this.photo) : '';
    },
    passwordErrors() {
      return this.getFieldErrors('password');
    },
    rePasswordErrors() {
      return this.getFieldErrors('confirm_password');
    }
  },
  mounted() {
    this.manage_controller = new ManageController(this);
  },

  methods: {
    uploadPhoto(file) {
      if(file) {
        File.uploadFile(file, '/users', (r) => {
          this.photo = r.data.rutacuenta_encode_token;
          console.log('Photo updated:', this.photo);
          console.log('Full URL:', File.fileUrl(this.photo));
        });
        
        setTimeout(() => {
          this.upload_photo = null;
        }, 250);
      }
    },
  

    deletePhoto() {
      this.manage_controller.deletePhoto();
    },

    manageUser() {
      this.manage_controller.manageUser();
    },
    onSubmit() {
  console.log('Formulario enviado');
  console.log('Password:', this.password);
  console.log('Re-password:', this.re_password);
  
  if (this.$refs.form_password) {
    // Forzar la validación y obtener el estado
    const isValid = this.$refs.form_password.validate();
    console.log('Es válido:', isValid);

    // Obtener los errores de cada campo de forma segura
    const passwordErrors = this.getFieldErrors('password');
    const rePasswordErrors = this.getFieldErrors('confirm_password');
    
    console.log('Errores de contraseña:', passwordErrors);
    console.log('Errores de confirmación:', rePasswordErrors);

    if (isValid) {
      console.log('Formulario válido');
      this.managePassword();
    } else {
      console.log('Formulario inválido');
    }
  } else {
    console.error('Referencia del formulario no encontrada');
  }
},

// Método auxiliar para obtener errores de campo de forma segura
getFieldErrors(fieldName) {
  if (this.$refs.form_password && this.$refs.form_password.inputs) {
    const field = this.$refs.form_password.inputs.find(input => input.id === fieldName);
    return field ? field.errorBucket : [];
  }
  return [];
},
  
    managePassword() {
      console.log('Intentando actualizar contraseña');
      // Aquí va tu lógica actual de managePassword
      this.manage_controller.managePassword();
    }
  }
}
</script>