<!-- eslint-disable-next-line vue/multi-word-component-names -->

<template>
  <div>
    <section class="main">
      <div class="container-fluid main-box-content contentModulo pt-2 pb-4">
        <div class="breadcrumb px-3">
          <ul>
            <li>Inicio</li>
            <li>Perfil</li>
          </ul>
        </div>

        <div class="title">
          <h1 class="my-0 fw-700">Mi perfil</h1>
        </div>

        <v-card class="mt-3 mt-md-4">
          <v-card-text :class="!template ? 'pb-0 px-md-4 pt-md-4' : 'px-md-4 py-md-4'">
            <div class="row" v-if="!template">
              <div class="col-md-4 col-lg-3 mb-4">
                <img :src="File.fileUrl(photo)" class="d-block mx-auto mb-3" width="100%" alt="" v-if="photo">

                <h4 class="fw-700 fz-26 text-center my-0 text-danger">{{names + ' ' + surnames}}</h4>

                <div class="d-flex justify-content-center mt-4">
                  <v-btn type="button" color="primary" href="#/profile/manage">Editar perfil</v-btn>
                </div>
              </div>
              <div class="col-md-8 col-lg-9">
                <div class="row">
                  <div class="col-md-4 mb-4 mb-md-5" v-if="position">
                    <h4 class="mt-0 mb-1 text-left text-standar fw-600 fz-18">Cargo:</h4>
                    <p class="my-0 fw-400 fz-16 text-black">{{position}}</p>
                  </div>
                  <div class="col-md-4 mb-4 mb-md-5" v-if="type">
                    <h4 class="mt-0 mb-1 text-left text-standar fw-600 fz-18">Tipo:</h4>
                    <p class="my-0 fw-400 fz-16 text-black">{{type}}</p>
                  </div>
                  <div class="col-md-4 mb-4 mb-md-5" v-if="phone_number">
                    <h4 class="mt-0 mb-1 text-left text-standar fw-600 fz-18">Teléfono:</h4>
                    <p class="my-0 fw-400 fz-16 text-black">{{phone_number}}</p>
                  </div>
                  <div class="col-md-4 mb-4 mb-md-5" v-if="email">
                    <h4 class="mt-0 mb-1 text-left text-standar fw-600 fz-18">Correo electrónico:</h4>
                    <p class="my-0 fw-400 fz-16 text-black">{{email}}</p>
                  </div>
                  <div class="col-12 mb-4 mb-md-5" v-if="qr_code">
                    <v-btn color="primary" max-width="160" class="d-flex fw-600 rounded" @click="openQrCode()">
                      <v-icon class="mr-2">mdi-qrcode</v-icon>
                      Ver código QR
                    </v-btn>

                    <v-dialog v-model="dialog_qr[0]" width="290">
                      <v-card>
                        <div class="title d-flex align-items-center justify-content-between flex-wrap p-4">
                          <h1 class="my-0">Código QR</h1>
                          <v-btn icon color="danger" @click="closeQrCode()">
                            <v-icon>mdi-close</v-icon>
                          </v-btn>
                        </div>

                        <v-card-text class="scroll-modal-quotations">
                          <div v-html="qr_code" class="qr-code"></div>
                        </v-card-text>
                      </v-card>
                    </v-dialog>
                  </div>
                  <div class="col-12 mb-4">
                    <ul class="mx-0 my-0 p-0 list-unstyled d-flex align-items-center justify-content-start flex-wrap">
                      <li class="mx-2 mb-3" v-if="website">
                        <v-btn icon outlined large color="success" target="_blank" :href="website">
                          <v-icon>mdi-web</v-icon>
                        </v-btn>
                      </li>
                      <li class="mx-2 mb-3" v-if="twitter">
                        <v-btn icon outlined large color="success" target="_blank" :href="twitter">
                          <v-icon>mdi-twitter</v-icon>
                        </v-btn>
                      </li>
                      <li class="mx-2 mb-3" v-if="facebook">
                        <v-btn icon outlined large color="success" target="_blank" :href="facebook">
                          <v-icon>mdi-facebook</v-icon>
                        </v-btn>
                      </li>
                      <li class="mx-2 mb-3" v-if="linkedin">
                        <v-btn icon outlined large color="success" target="_blank" :href="linkedin">
                          <v-icon>mdi-linkedin</v-icon>
                        </v-btn>
                      </li>
                      <li class="mx-2 mb-3" v-if="instagram">
                        <v-btn icon outlined large color="success" target="_blank" :href="instagram">
                          <v-icon>mdi-instagram</v-icon>
                        </v-btn>
                      </li>
                      <li class="mx-2 mb-3" v-if="skype">
                        <v-btn icon outlined large color="success" target="_blank" :href="skype">
                          <v-icon>mdi-skype</v-icon>
                        </v-btn>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <div class="template-code profile" v-if="template" v-html="template"></div>

            <v-dialog v-model="dialog_qr[0]" width="290" v-if="template">
              <v-card>
                <div class="title d-flex align-items-center justify-content-between flex-wrap p-4">
                  <h1 class="my-0">Código QR</h1>

                  <v-btn icon color="danger" @click="closeQrCode()">
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </div>

                <v-card-text class="scroll-modal-quotations">
                  <h2 class="fw-700 fz-26 text-center my-0 text-custom-danger">{{name_modal}}</h2>

                  <h4 class="fw-700 fz-22 text-center mb-0 mt-2 text-custom-warning">{{position}}</h4>

                  <div v-html="qr_code" class="qr-code"></div>
                </v-card-text>
              </v-card>
            </v-dialog>
            
            <div class="d-flex align-items-center justify-content-center mt-3 mt-lg-4" v-if="template">
              <v-btn color="primary" outlined max-width="160" class="d-flex fw-600 mx-2 rounded" @click="openQrCode()">
                <v-icon class="mr-2">mdi-qrcode</v-icon>
                Ver código QR
              </v-btn>

              <v-btn type="button" class="fw-600 mx-2" color="primary" href="#/profile/manage">Editar perfil</v-btn>
            </div>
          </v-card-text>
        </v-card>
      </div>
    </section>
  </div>
</template>

<script>
import Helpers from '../../../core/helpers/Helpers';
import MainController from '../controllers/MainController';
import File from "../../../core/helpers/File";

export default {
  name: 'ProfileMain', 
  data() {
    return {
      Helpers,
      File,
      dialog_qr: {},
      user_id: null,
      photo: '',
      names: '',
      surnames: '',
      position: '',
      type: '',
      phone_number: '',
      email: '',
      qr_code: '',
      name_modal: '',
      website: '',
      twitter: '',
      facebook: '',
      linkedin: '',
      instagram: '',
      skype: '',
      template: '',
      main_controller: null
    };
  },

  computed: {
    fullName() {
      return `${this.names} ${this.surnames}`;
    },
    cardTextClass() {
      return this.template ? 'px-md-4 py-md-4' : 'pb-0 px-md-4 pt-md-4';
    },
    socialLinks() {
      return {
        website: this.website,
        twitter: this.twitter,
        facebook: this.facebook,
        linkedin: this.linkedin,
        instagram: this.instagram,
        skype: this.skype
      };
    }
  },
  created() {
    this.userId = this.$route.params.id;
    console.log('User ID:', this.userId); // Añade esta línea para depuración

    this.loadUserProfile();
  },

  mounted() {
    this.main_controller = new MainController(this);
  },

  methods: {
    openQrCode() {
      this.main_controller.openQrCode();
    },
    closeQrCode() {
      this.main_controller.closeQrCode();
    },
    async loadUserProfile() {
      try {
        const profileData = await this.main_controller.getProfileData(this.userId);
        Object.assign(this, profileData);
      } catch (error) {
        console.error('Error al cargar el perfil:', error);
        // Aquí puedes manejar el error, por ejemplo, mostrando un mensaje al usuario
      }
    }
  }
}
</script>