var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('section',{staticClass:"main"},[_c('div',{staticClass:"container-fluid main-box-content contentModulo pt-2 pb-4"},[_c('div',{staticClass:"breadcrumb px-3"},[_c('ul',[_c('li',[_vm._v("Inicio")]),_c('li',[_vm._v("Plantillas")]),_c('li',[_vm._v(_vm._s(!_vm.is_edit ? 'Crear' : 'Actualizar')+" plantilla")])])]),_c('div',{staticClass:"title"},[_c('h1',{staticClass:"my-0 fw-700"},[_vm._v(_vm._s(!_vm.is_edit ? 'Crear' : 'Actualizar')+" plantilla")])]),_c('v-card',{staticClass:"mt-3 mt-md-4"},[_c('v-card-text',[_c('v-form',{ref:"form_template",staticClass:"w-100",attrs:{"method":"post","lazy-validation":""},on:{"submit":function($event){$event.preventDefault();return _vm.manageTemplate()}},model:{value:(_vm.form_valid),callback:function ($$v) {_vm.form_valid=$$v},expression:"form_valid"}},[_c('v-text-field',{attrs:{"filled":"","label":"Titulo","rules":[_vm.rules.requerido]},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}}),_c('editor',{attrs:{"label":"Código","rules":[_vm.rules.requerido],"api-key":"ervrkz377gfbh7cir9aeb9xq3yhhdmffd0vv92gazvndi40r","init":{
                height: 400,
                menubar: false,
                plugins: [
                  'advlist autolink lists link image charmap print preview anchor',
                  'searchreplace visualblocks code fullscreen',
                  'insertdatetime media table paste code help wordcount'
                ],
                toolbar: 'undo redo | code | \
                bullist numlist outdent | removeformat',
              },"output-format":"html"},model:{value:(_vm.code),callback:function ($$v) {_vm.code=$$v},expression:"code"}}),_c('v-btn',{staticClass:"d-table mx-auto mt-3",attrs:{"type":"submit","rounded":"","color":"primary","min-width":"150"}},[_vm._v("Guardar")])],1)],1)],1)],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }