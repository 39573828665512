<template>
  <div>
    <section class="main">
      <div class="container-fluid main-box-content contentModulo pt-2 pb-4">
        <div class="breadcrumb px-3">
          <ul>
            <li>Inicio</li>
            <li>Perfil</li>
            <li>Editar perfil</li>
          </ul>
        </div>

        <div class="title">
          <h1 class="my-0 fw-700">Editar perfil</h1>
        </div>

        <v-card class="mt-3 mt-md-4">
          <v-card-text class="px-md-4 py-md-4">
            <v-form class="w-100" ref="form_profile" method="post" v-model="form_valid" lazy-validation @submit.prevent="manageMyProfile()">
              <div class="row">
                <div class="col-md-6">
                  <v-text-field filled label="Correo electrónico" type="email" v-model="email" disabled></v-text-field>
                </div>
                <div class="col-md-6">
                  <v-text-field filled label="Teléfono" type="tel" v-model="phone_number" :rules="[rules.requerido]"></v-text-field>
                </div>
                <div class="col-md-6">
                  <v-file-input filled label="Imagen razón social" :prepend-icon="null" @change="uploadPhoto($event)" v-model="upload_photo" v-if="!photo"></v-file-input>

                  <div class="row pb-4" v-if="photo">
                    <div class="col-sm-8">
                      <img :src="File.fileUrl(photo)" class="d-block" width="100%" alt="">
                    </div>
                    <div class="col-sm-4">
                      <v-btn color="danger" @click="deletePhoto()" width="100%">Eliminar</v-btn>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <v-text-field filled label="Sitio web" v-model="website" type="url"></v-text-field>
                </div>
                <div class="col-md-6">
                  <v-text-field filled label="Facebook" type="url" v-model="facebook"></v-text-field>
                </div>
                <div class="col-md-6">
                  <v-text-field filled label="Instagram" type="url" v-model="instagram"></v-text-field>
                </div>
                <div class="col-md-6">
                  <v-text-field filled label="Twitter" type="url" v-model="twitter"></v-text-field>
                </div>
                <div class="col-md-6">
                  <v-text-field filled label="Linkedin" type="url" v-model="linkedin"></v-text-field>
                </div>
                <div class="col-md-6">
                  <v-text-field filled label="Skype" type="url" v-model="skype"></v-text-field>
                </div>
                <!-- <div class="col-md-6">
                  <h3 class="my-0 fw-400 fz-18 text-black fw-600">¿Crear tarjeta?</h3>

                  <v-radio-group v-model="show_card" row :rules="[rules.requerido]" class="row-radio mt-3">
                    <v-radio value="1" label="Si" class="text-black mr-4"></v-radio>
                    <v-radio value="2" label="No" class="text-black"></v-radio>
                  </v-radio-group>
                </div> -->
              </div>

              <v-btn type="submit" rounded color="success" min-width="150" class="d-table mx-auto mt-3">Actualizar</v-btn>
            </v-form>
          </v-card-text>
        </v-card>

        <v-card class="mt-3 mt-md-4">
          <v-card-title class="px-md-4 pb-0 fw-700">
            Actualizar contraseña
          </v-card-title>
          <v-card-text class="pb-0 px-md-4 pt-md-4">
            <v-form class="w-100" ref="form_password" method="post" v-model="form_valid" lazy-validation @submit.prevent="managePassword()">
              <div class="row">
                <div class="col-md-10">
                  <div class="row">
                    <div class="col-md-6">
                      <v-text-field filled label="Contraseña" :append-icon="!show_pass ? 'mdi-eye' : 'mdi-eye-off'" :type="show_pass ? 'text' : 'password'" @click:append="show_pass = !show_pass" v-model="password" :rules="[rules.requerido]"></v-text-field>
                    </div>
                    <div class="col-md-6">
                      <v-text-field filled label="Confirmar contraseña" :append-icon="!show_re_pass ? 'mdi-eye' : 'mdi-eye-off'" :type="show_re_pass ? 'text' : 'password'" @click:append="show_re_pass = !show_re_pass" v-model="re_password" :rules="[rules.requerido]"></v-text-field>
                    </div>
                  </div>
                </div>
                <div class="col-md-2">
                  <v-btn color="success" type="submit" min-height="56" width="100%">Guardar</v-btn>
                </div>
              </div>
            </v-form>
          </v-card-text>
        </v-card>
      </div>
    </section>
  </div>
</template>

<script>
import Helpers from '../../../core/helpers/Helpers';
import ManageController from '../controllers/ManageController';
import File from "../../../core/helpers/File";

export default {
  name: 'ManageProfile',
  data() {
    return {
      Helpers,
      File,

      first_name: '',
      second_name: '',
      first_surname: '',
      second_surname: '',
      type_id: '',
      number_id: null,
      type_user: null,
      email: '',
      position: null,
      upload_photo: null,
      //photo: '8ae0206c489ce5-tk-_sl_Fcontacts-profile_sl_F2022_sl_F07_sl_F26_sl_F1658874230_om_Cat%25C3%25A1logo-Blackday-Store.jpg',
      photo: '',
      template: null,
      show_card: '1',
      website: '',
      facebook: '',
      instagram: '',
      twitter: '',
      linkedin: '',
      skype: '',
      phone_number: '',
      list_created_card: [],

      password: '',
      show_pass: false,
      re_password: '',
      show_re_pass: false,

      form_valid: true,
      rules: {
        requerido: value => !!value || 'Campo obligatorio',
        email: value => !value || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(value) || 'Email incorrecto',
      },

      manage_controller: null
    };
  },

  mounted() {
    this.manage_controller = new ManageController(this);
  },

  methods: {
    async managePassword() {
    console.log('Intentando actualizar contraseña');
    if (this.$refs.form_password.validate()) {
      try {
        // Asume que tienes el userId almacenado en algún lugar, por ejemplo this.userId
        const response = await this.manageService.managePassword(this.userId, this.password, this.re_password);
        console.log('Respuesta del servidor:', response);
        
        if (response.success) {
          // Maneja la respuesta exitosa
          this.$toast.success(response.msg || 'Contraseña actualizada con éxito');
          // Limpia los campos de contraseña
          this.password = '';
          this.re_password = '';
          this.$refs.form_password.reset();
        } else {
          // Maneja el caso en que la operación no fue exitosa
          this.$toast.error(response.msg || 'Error al actualizar la contraseña');
        }
      } catch (error) {
        console.error('Error al actualizar la contraseña:', error);
        this.$toast.error('Error al actualizar la contraseña');
      }
    } else {
      console.log('Formulario inválido');
    }
  },

    manageMyProfile() {
      this.manage_controller.manageMyProfile();
    },

    uploadPhoto(file) {
      this.manage_controller.uploadPhoto(file);
    },

    deletePhoto() {
      this.manage_controller.deletePhoto();
    }
  }
}
</script>