<template>
  <div>
    <section class="main">
      <div class="container-fluid main-box-content contentModulo pt-2 pb-4">
        <div class="breadcrumb px-3">
          <ul>
            <li>Inicio</li>
            <li>Usuarios</li>
            <li>Listado de usuarios</li>
          </ul>
        </div>

        <div class="title">
          <h1 class="my-0 fw-700">Listado de usuarios</h1>
        </div>

        <v-card class="mt-3 mt-md-4">
          <v-card-text>
            <div class="row">
              <div class="col-md-8 col-lg-9 col-xl-10">
                <v-text-field
                  filled
                  v-model="search_list"
                  append-icon="mdi-magnify"
                  label="Buscar"
                  hide-details
                  clearable
                  class="my-0"
                ></v-text-field>
              </div>
              <div class="col-md-4 col-lg-3 col-xl-2">
                <v-btn color="success" width="100%" min-height="56" href="#/users/manage">Crear usuario</v-btn>
              </div>
            </div>

            <div class="table-responsive mt-3 mt-md-4">
              <v-data-table
                class="my-0"
                :headers="headers"
                :items="lists"
                :items-per-page="30"
                :search="search_list"
              >
              <template v-slot:[`item.state`]="{ item }">
                  {{item.estado == 1 ? 'Activo' : 'Bloqueado'}}
                </template>

                <template v-slot:[`item.actions`]="{ item }">
                  <div class="d-flex align-items-center justify-content-center flex-nowrap">
                    <v-dialog v-model="dialog_detail[item.usuario_id]" width="460">
                      <template v-slot:activator="{ on, attrs }">
                        <v-tooltip bottom v-bind="attrs" v-on="on">
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn icon color="success" v-bind="attrs" v-on="on" @click="openDialog(item.usuario_id)">
                              <v-icon>mdi-eye-outline</v-icon>
                            </v-btn>
                          </template>
                          <span>Ver detalle</span>
                        </v-tooltip>
                      </template>

                      <v-card>
                        <div class="title d-flex align-items-center justify-content-between flex-wrap p-4">
                          <h1 class="my-0">Detalle del usuario</h1>
                          <v-btn icon color="danger" @click="closeDetail(item.usuario_id)">
                            <v-icon>mdi-close</v-icon>
                          </v-btn>
                        </div>

                        <v-card-text class="scroll-modal-quotations">
                          <img :src="File.fileUrl(item.foto)" width="160" height="160" class="d-block rounded-circle mx-auto overflow-hidden" :alt="item.names + ' ' + item.surnames" v-if="item.foto">

                          <ul class="mx-0 mt-3 mt-md-4 p-0 list-unstyled text-black fz-16 text-center">
                            <li class="py-2" v-if="item.names">
                              <span class="fw-700">Nombres:</span> {{item.names}}
                            </li>
                            <li class="py-2" v-if="item.surnames">
                              <span class="fw-700">Apellidos:</span> {{item.surnames}}
                            </li>
                            <li class="py-2" v-if="item.telefono">
                              <span class="fw-700">Teléfono:</span> {{item.telefono}}
                            </li>
                            <li class="py-2" v-if="item.email">
                              <span class="fw-700">Correo electrónico:</span>
                              <a :href="'mailto:'+item.email" target="_blank" class="pl-2">{{item.email}}</a>
                            </li>
                          </ul>

                          <ul class="mx-0 mt-3 mt-md-4 p-0 list-unstyled d-flex align-items-center justify-content-center" v-if="item.website || item.twitter || item.facebook || item.linkedin || item.instagram || item.skype">
                            <li class="mx-2" v-if="item.website">
                              <v-btn icon outlined large color="success" :href="item.website" target="_blank">
                                <v-icon>mdi-web</v-icon>
                              </v-btn>
                            </li>
                            <li class="mx-2" v-if="item.twitter">
                              <v-btn icon outlined large color="success" :href="item.twitter" target="_blank">
                                <v-icon>mdi-twitter</v-icon>
                              </v-btn>
                            </li>
                            <li class="mx-2" v-if="item.facebook">
                              <v-btn icon outlined large color="success" :href="item.facebook" target="_blank">
                                <v-icon>mdi-facebook</v-icon>
                              </v-btn>
                            </li>
                            <li class="mx-2" v-if="item.linkedin">
                              <v-btn icon outlined large color="success" :href="item.linkedin" target="_blank">
                                <v-icon>mdi-linkedin</v-icon>
                              </v-btn>
                            </li>
                            <li class="mx-2" v-if="item.instagram">
                              <v-btn icon outlined large color="success" :href="item.instagram" target="_blank">
                                <v-icon>mdi-instagram</v-icon>
                              </v-btn>
                            </li>
                            <li class="mx-2" v-if="item.skype">
                              <v-btn icon outlined large color="success" :href="item.skype" target="_blank">
                                <v-icon>mdi-skype</v-icon>
                              </v-btn>
                            </li>
                          </ul>

                          <div class="d-flex justify-content-center mt-4">
                            <v-btn color="primary" :href="'#/web/profile/'+item.usuario_id">Ver perfil</v-btn>
                          </div>
                        </v-card-text>
                      </v-card>
                    </v-dialog>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          icon
                          color="primary"
                          v-bind="attrs"
                          v-on="on"
                          @click.prevent="handleEdit(item.usuario_id)"
                        >
                          <v-icon>mdi-pencil-outline</v-icon>
                        </v-btn>
                      </template>
                      <span>Editar (ID: {{ item.usuario_id }})</span>
                    </v-tooltip>
                    
                    <!-- <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn icon :color="item.estado == 1 ? 'danger' : 'success'" v-bind="attrs" v-on="on" @click="openConfirm(item.usuario_id, item.estado, lists.map(function(x) { return x.usuario_id; }).indexOf(item.usuario_id))">
                          <v-icon>{{item.estado == 1 ? 'mdi-lock-outline' : 'mdi-lock-open-outline'}}</v-icon>
                        </v-btn>
                      </template>
                      <span>{{item.estado == 1 ? 'Bloquear' : 'Desbloquear'}}</span>
                    </v-tooltip> -->
                  </div>
                </template>
              </v-data-table>
            </div>
          </v-card-text>
        </v-card>

        <v-dialog v-model="dialog_confirm[0]" width="460">
          <v-card>
            <div class="title d-flex align-items-center justify-content-between flex-wrap p-4">
              <h1 class="my-0">{{state_confirm == 1 ? '¿Bloquear al usuario?' : '¿Desbloquear al usuario?'}}</h1>
              <v-btn icon color="danger" @click="closeConfirm()">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </div>

            <v-card-text>
              <div class="d-flex align-items-center justify-content-center">
                <v-btn text color="success" class="mx-2 fz-26 fw-600" @click="toggleLockUser(usuario_id_confirm, state_confirm, position_confirm)">Si</v-btn>
                <v-btn text color="primary" class="mx-2 fz-26 fw-600" @click="closeConfirm()">No</v-btn>
              </div>
            </v-card-text>
          </v-card>
        </v-dialog>
      </div>
    </section>
  </div>
</template>

<script>
import Helpers from '../../../core/helpers/Helpers';
import File from "../../../core/helpers/File";
import ListController from '../controllers/ListController';

export default {
  name: 'UserList',
  data() {
    return {
      Helpers,
      File,
      headers: [],
      lists: [],
      search_list: '',
      dialog_detail: {},
      dialog_confirm: {},
      usuario_id_confirm: '',
      state_confirm: '',
      position_confirm: '',
      list_controller: null
    };
  },
    mounted() {
    this.list_controller = new ListController(this);
  },

  computed: {
    editUrl() {
      return (userId) => `#/users/manage/${userId}`;
    }
  },

  methods: {
    toggleLockUser(usuario_id, state, position) {
      this.list_controller.toggleLockUser(usuario_id, state, position);
    },

    closeDetail(usuario_id) {
      this.list_controller.closeDetail(usuario_id);
    },

    openDialog(usuario_id) {
      this.list_controller.openDialog(usuario_id);
    },

    openConfirm(usuario_id, state, position) {
      this.list_controller.openConfirm(usuario_id, state, position);
    },

    closeConfirm() {
      this.list_controller.closeConfirm();
    },

    debugClick(userId) {
      console.log('ID del usuario:', userId);
      console.log('URL de edición:', this.editUrl(userId));
      // Descomenta la siguiente línea si quieres permitir la navegación después de loggear
      // window.location.href = this.editUrl(userId);
    },
    async handleEdit(userId) {
  console.log('ID del usuario:', userId);
  const editUrl = `/users/manage/${userId}`; // Cambiamos a una ruta sin #
  console.log('URL de edición:', editUrl);

  try {
    // Verificar si ya estamos en la ruta de edición
    if (this.$route.path !== editUrl) {
      await this.$router.push(editUrl);
    } else {
      console.log('Ya estás en la página de edición de este usuario.');
      // Aquí puedes agregar alguna acción adicional si es necesario,
      // como refrescar los datos del usuario o mostrar un mensaje al usuario
    }
  } catch (error) {
    if (error.name !== 'NavigationDuplicated') {
      console.error('Error de navegación:', error);
      // Aquí puedes manejar otros tipos de errores de navegación
    }
  }
}
  }
}
</script>