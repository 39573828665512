import Rest from '../../../core/libs/Rest';
import { MAIN_MOCKUP } from '../mockups/Main';

class MainRepository {
  rest;

  constructor() {
    this.rest = new Rest();
  }

  infoProfile() {
    this.rest.setParams('');
    this.rest.setEndPoint('/api/card/contact/my-profile');
    return this.rest.get();
  }
}

export default MainRepository;