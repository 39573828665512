import Rest from '../../../core/libs/Rest';
// import { TYPES_USERS_MOCKUP } from '../mockups/PositionsUsers';

class PositionsUsersRepository {
  rest;

  constructor() {
    this.rest = new Rest();
  }

  listPositionsUsers() {
    this.rest.setParams('');
    this.rest.setEndPoint('/api/card/position/paginate-all');
    return this.rest.get();
  }

  deletePositionUser(position_id) {
    console.log ('Inicio Borrado');
    this.rest.setParams('');
    this.rest.setEndPoint('/api/card/position/delete-position');
    console.log ('setea endpoing');
    // return this.rest.patch(position_id);
    return this.rest.put(position_id);
  }

  createPositionUser(name, description) {
    this.rest.setParams({name:name, description:description});
    this.rest.setEndPoint('https://soy.esenttia.co/api/card/position/store-position');
    return this.rest.post();
  }

  updatePositionUser(position_id, name, description) {
    this.rest.setParams({name:name, description:description});
    this.rest.setEndPoint('/api/card/position/update-position');
    return this.rest.put(position_id);
  }
}

export default PositionsUsersRepository;