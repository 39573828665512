import ManageRepository from "../repositories/ManageRepository";
import axios from 'axios';

class ManageService {
  manage_repository;

  constructor() {
    this.manage_repository = new ManageRepository();
  }

  async listTypeUsers() {
    let r = await this.manage_repository.listTypeUsers();
    return r;
  }

  async listPositionsUsers() {
    console.log("Llamando a listPositionsUsers en ManageService");
    let r = await this.manage_repository.listPositionsUsers();
    console.log("Respuesta en ManageService:", r);
    return r;
  }

  async listTemplates() {
    let r = await this.manage_repository.listTemplates();
    return r;
  }

  async infoUser(user_id) {
    let r = await this.manage_repository.infoUser(user_id);
    return r;
  }

  async createUser(first_name, second_name, first_surname, second_surname, type_id, number_id, type_user, email, position, password, re_password, photo, template, show_card, website, facebook, instagram, twitter, linkedin, skype, phone_number, address) {
    let r = await this.manage_repository.createUser(first_name, second_name, first_surname, second_surname, type_id, number_id, type_user, email, position, password, re_password, photo, template, parseInt(show_card), website, facebook, instagram, twitter, linkedin, skype, phone_number, address);
    return r;
  }

  async editUser(user_id, first_name, second_name, first_surname, second_surname, type_id, number_id, type_user, email, position, password, re_password, photo, template, show_card, website, facebook, instagram, twitter, linkedin, skype, phone_number, address) {
    let r = await this.manage_repository.editUser(user_id, first_name, second_name, first_surname, second_surname, type_id, number_id, type_user, email, position, password, re_password, photo, template, parseInt(show_card), website, facebook, instagram, twitter, linkedin, skype, phone_number, address);
    return r;
  }

  async managePassword(user_id, password, re_password) {
    let r = await this.manage_repository.managePassword(user_id, password, re_password);
    return r;
  }
}

export default ManageService;