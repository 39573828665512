import Rest from '../../../core/libs/Rest';
import axios from 'axios';
import apiClient from '../../../core/services/apiClient';

// import { MANAGE_MOCKUP } from '../mockups/Manage';

class ManageRepository {
  rest;

  constructor() {
    this.rest = new Rest();
  }

  listTypeUsers() {
    this.rest.setParams('');
    this.rest.setEndPoint('api/card/type/list-all');
    return this.rest.get();
  }

  async listPositionsUsers() {
    console.log("Llamando a la API para obtener la lista de cargos");
    try {
      const response = await apiClient.get('api/card/position/list-all');
      console.log("Respuesta de la API:", response.data);
      return response.data;
    } catch (error) {
      console.error("Error al obtener la lista de cargos:", error);
      throw error;
    }
  }

  listTemplates() {
    this.rest.setParams('');
    this.rest.setEndPoint('/api/card/template/list-all');
    return this.rest.get();
  }

  infoUser(user_id) {
    this.rest.setParams('/'+user_id);
    this.rest.setEndPoint('/api/card/contact/show-contact');
    return this.rest.get();
  }

  async createUser(first_name, second_name, first_surname, second_surname, type_id, number_id, type_user, email, position, password, re_password, photo, template, show_card, website, facebook, instagram, twitter, linkedin, skype, phone_number, address) {
    console.log("Llamando a la API para crear un nuevo usuario");
    try {
      const response = await apiClient.post('api/card/contact/store-contact', {
        pri_nombre: first_name,
        seg_nombre: second_name,
        pri_apellido: first_surname,
        seg_apellido: second_surname,
        tipo_doc: type_id,
        documento: number_id,
        tipo_id: type_user,
        email: email,
        position_id: position,
        password: password,
        password_confirmation: re_password,
        foto: photo,
        template_id: template,
        have_qr: show_card,
        website: website,
        facebook: facebook,
        instagram: instagram,
        twitter: twitter,
        linkedin: linkedin,
        skype: skype,
        telefono: phone_number,
        address: address
      });
      console.log("Respuesta de la API:", response.data);
      return response.data;
    } catch (error) {
      console.error("Error al crear el usuario:", error);
      throw error;
    }
  }

  editUser(user_id, first_name, second_name, first_surname, second_surname, type_id, number_id, type_user, email, position, password, re_password, photo, template, show_card, website, facebook, instagram, twitter, linkedin, skype, phone_number, address) {
    this.rest.setParams({pri_nombre :first_name, seg_nombre:second_name, pri_apellido:first_surname, seg_apellido:second_surname, tipo_doc:type_id, documento:number_id, tipo_id:type_user, email:email, position_id:position, password:'', password_confirmation:'', foto:photo, template_id:template, have_qr:show_card, website:website, facebook:facebook, instagram:instagram, twitter:twitter, linkedin:linkedin, skype:skype, telefono:phone_number, address:address});
    this.rest.setEndPoint('/api/card/contact/update-contact');
    return this.rest.put(user_id);
  }

  managePassword(user_id, password, re_password) {
    this.rest.setParams({password:password, password_confirmation:re_password});
    this.rest.setEndPoint('/api/card/contact/update-password');
    return this.rest.patch(user_id);
  }
}

export default ManageRepository;