<template>
  <div>
    <v-bottom-navigation background-color="custom-warning" color="white" v-model="header" height="auto" max-height="inherit" class="custom-navigation" grow>
      <v-btn value="profile" min-height="inherit" height="auto" class="my-2" :href="'#/web/profile/'+contact_id">
        <span class="fw-700 fz-14 mt-1 text-white">Contacto</span>

        <v-icon class="fz-34 text-white">mdi-card-account-phone-outline</v-icon>
      </v-btn>

      <v-btn value="company" min-height="inherit" height="auto" class="my-2" :href="'#/web/company/?id='+contact_id">
        <span class="fw-700 fz-14 mt-1 text-white">Empresa</span>

        <!-- <v-icon class="fz-34 text-white">mdi-factory</v-icon> -->
        <img src="../../_core/images/iconos.png" clasS="icono-family" alt="">
      </v-btn>

      <v-btn value="contact" min-height="inherit" height="auto" class="my-2" :href="'#/web/briefcase/?id='+contact_id">
        <span class="fw-700 fz-14 mt-1 text-white">Portafolio</span>

        <v-icon class="fz-34 text-white">mdi-briefcase-outline</v-icon>
      </v-btn>
    </v-bottom-navigation>

    <section class="p-3 mt-4 section-public section-public-profile min-vh-100">
      <img width="100%" class="bg-company" src="../../_core/images/bg-custom-empresa.jpg" />

      <div class="row justify-content-center pt-3 pt-md-4 position-relative">
        <div class="col-sm-12 col-md-10 col-lg-9">
          <div class="row justify-content-around align-items-center flex-lg-column">
            <div class="col-12 mb-5" v-if="photo_brand">
              <div class="logo_ese">
                <img width="100%" class="d-block mx-auto mt-4" :src="photo_brand" />
              </div>  
              <div class="d-flex justify-content-center align-items-center mb-0 pb-5" v-if="twitter || facebook || linkedin || skype || email">
                <a :href="twitter" class="d-block px-1" target="_blank" v-if="twitter">
                  <img max-width="32" width="32" height="32" class="d-block mx-auto" src="../../_core/images/twitter.png" />
                </a>
                <a :href="facebook" class="d-block px-1" target="_blank" v-if="facebook">
                  <img max-width="32" width="32" height="32" class="d-block mx-auto" src="../../_core/images/facebook.png" />
                </a>
                <a :href="linkedin" class="d-block px-1" target="_blank" v-if="linkedin">
                  <img max-width="32" width="32" height="32" class="d-block mx-auto" src="../../_core/images/linkedin.png" />
                </a>
                 <!-- neuvos  -->
                <a :href="skype" class="d-block px-1" target="_blank" v-if="skype">
                  <img max-width="32" width="32" height="32" class="d-block mx-auto" src="../../_core/images/Youtube.png" />
                </a>
                <a :href="instagram" class="d-block px-1" target="_blank" v-if="instagram">
                  <img max-width="32" width="32" height="32" class="d-block mx-auto" src="../../_core/images/Insta.png" />
                </a>
                <!-- 
                <a :href="skype" class="d-block px-1" target="_blank" v-if="skype">
                  <img max-width="32" width="32" height="32" class="d-block mx-auto" src="../../_core/images/skype.png" />
                </a>
                 -->
                <a :href="'mailto:'+email" class="d-block px-1" target="_blank" v-if="email">
                  <img max-width="32" width="32" height="32" class="d-block mx-auto" src="../../_core/images/email.png" />
                </a>
               

              </div>
            </div>

            <div class="col-12"> 
              <h3 class="custom-danger--text fz-24 mt-0 pt-5 fw-900 mb-0 text-center" v-if="city">{{city}}</h3>

              <h3 class="text-center mb-0 mt-4 fw-600 text-white fz-18" v-if="scheduled">
                Horario de atención
                <br>
                {{scheduled}}
              </h3>

              <hr class="line-white my-5" v-if="phone">

              <h3 class="text-center my-0 fw-600 text-white fz-18" v-if="phone">Conmutador {{phone}}</h3>

              <hr class="line-white">

              <div class="d-flex align-items-center flex-wrap justify-content-center mb-3 cursor-pointer" v-if="list_address.length == 1" @click="openGoogleMaps(list_address[0].google_maps_link)">
                <v-icon color="#FF8201" class="w-auto h-auto fz-32">mdi-map-marker</v-icon>
                <h3 class="text-center my-0 fw-600 text-white fz-18">Ver en Google Maps</h3>
              </div>

              <div class="d-flex align-items-center flex-wrap justify-content-center mb-3 cursor-pointer" v-if="list_address.length > 1" @click="openAddress()">
                <v-icon color="#FF8201" class="w-auto h-auto fz-32">mdi-map-marker</v-icon>
                <h3 class="text-center my-0 fw-600 text-white fz-18">{{list_address.length == 1 ? 'Ver en Google Maps' : 'Ver listado de direcciones'}}</h3>
              </div>

              <v-dialog v-model="dialog_address[0]" width="560" v-if="list_address.length > 1">
                <v-card>
                  <v-card-text class="scroll-modal-quotations">
                    <ul class="mx-0 mt-3 mt-md-4 p-0 list-unstyled text-black fz-16 text-center">
                      <template v-for="(item, i) in list_address">
                        <li class="py-2" :key="i">
                          <span class="fw-700">Dirección {{i+1}}:</span>

                          <a :href="item.google_maps_link" target="_blank" class="text-underline"> {{item.address}}</a>
                        </li>
                      </template>
                    </ul>

                    <v-btn max-width="38" min-width="38" width="38" height="38" rounded color="custom-danger px-0" @click="closeAddress()" class="mt-3 d-table mx-auto">
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                  </v-card-text>
                </v-card>
              </v-dialog>

              <h3 class="text-center my-0 fw-600 text-white fz-18" v-if="list_address.length == 1">{{list_address[0].address}}</h3>

              <div class="d-flex justify-content-center mt-4" v-if="url1">
                <v-btn color="black" target="_blank" :href="url1">
                  <span class="text-uppercase fw-600 customer-danger--text">{{descripcion1}}</span>
                </v-btn>
              </div>

              <!-- nuevos  -->

              <div class="d-flex justify-content-center mt-4" v-if="url2">
                <v-btn color="white" target="_blank" :href="url2">
                  <span class="text-uppercase fw-600 customer-danger--text">{{descripcion2}}</span>
                </v-btn>
              </div>

              <div class="d-flex justify-content-center mt-4" v-if="url3">
                <v-btn color="white" target="_blank" :href="url3">
                  <span class="text-uppercase fw-600 customer-danger--text">{{descripcion3}}</span>
                </v-btn>
              </div>




            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import Helpers from '../../../core/helpers/Helpers';
import CompanyController from '../controllers/CompanyController';

export default {
  name: 'CompanyView',
  data() {
    return {
      Helpers,
      scheduled: '',
      header: 'company',
      list_address: [],
      dialog_address: {},
      photo_brand: '',
      phone: '',
      website: '',
      twitter: '',
      facebook: '',
      linkedin: '',
      skype: '',
      instagram: '',
      email: '',
      city: '',
      contact_id: null,
      url1: '',
      url2: '',
      url3: '',
      descripcion1: '',
      descripcion2: '',
      descripcion3: '',

      company_controller: null
    };
  },

  mounted() {
    this.company_controller = new CompanyController(this);
  },

  methods: {
    openAddress() {
      this.company_controller.openAddress();
    },

    closeAddress() {
      this.company_controller.closeAddress();
    },

    openGoogleMaps(google_maps) {
      this.company_controller.openGoogleMaps(google_maps);
    }
  }
}
</script>